.mbsc-calendar,
.mbsc-calendar-controls,
.mbsc-calendar-title-wrapper {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.mbsc-popup-wrapper {
  z-index: 10 !important;
}

.mbsc-schedule-header {
  background-color: white;
}

.mbsc-ios.mbsc-schedule-header {
  border: none !important;
}

.mbsc-schedule-fake-scroll-y {
  display: none;
}

.mbsc-ios a {
  color: rgb(75 85 99) !important;
}

.mbsc-schedule-column {
  padding: 0 !important;
}

.mbsc-schedule-all-day-item:first-child,
.mbsc-schedule-column:first-child {
  border-left: 1px gray solid;
}

.mbsc-schedule-grid-scroll,
.mbsc-timeline-grid-scroll {
  overflow-y: hidden !important;
}

.md-custom-event-cont {
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0 0px 10px 0;
  background-color: transparent;
  box-sizing: border-box;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.3);
}

.md-custom-event-wrapper {
  position: relative;
  height: 100%;
  min-height: 135px;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 0 10px 10px 0;
  transition: background 0.15s ease-in-out;
  background-color: white;
}

.md-custom-event-wrapper-closed {
  background-color: #f5f5f5;
}

/* 
  TOOLTIP
*/

.md-tooltip .mbsc-popup-content {
  padding: 0;
}

.md-tooltip {
  font-size: 15px;
  font-weight: 600;
}

.md-tooltip-header {
  padding: 12px 16px;
  color: #eee;
}

.md-tooltip-info {
  padding: 16px 16px 60px 16px;
  position: relative;
  line-height: 32px;
}

.md-tooltip-time,
.md-tooltip-status-button {
  float: right;
}

.md-tooltip-title {
  margin-bottom: 15px;
}

.md-tooltip-text {
  font-weight: 300;
}

.md-tooltip-info .mbsc-button {
  font-size: 14px;
  margin: 0;
}

.md-tooltip-info .mbsc-button.mbsc-material {
  font-size: 12px;
}

.md-tooltip-view-button {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.md-tooltip-delete-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.mbsc-windows.mbsc-popup {
  border: none !important;
}

.mbsc-windows-dark.mbsc-popup {
  background: none !important;
  border-color: white !important;
  border-radius: 0.5rem;
}

.mbsc-popup-body-round {
  border-radius: 0.5rem;
}

.mbsc-popup-arrow {
  color: white !important;
  background-color: white !important;
  border: white 1px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.mbsc-material.mbsc-calendar-today {
  background-color: var(--accent-color-600) !important;
  border-color: var(--accent-color-600) !important;
  color: #fff !important;
}

.today {
  background-color: #e0f2fe !important;
  padding: 0 !important;
  width: 100% !important;
}

@media (min-width: 800px) {
  .today {
    background-color: white !important;
  }

  .mbsc-calendar-cell-text {
    background: none !important;
    border-color: white !important;
    color: black !important;
    border: 2px solid transparent !important;
  }
}

.mbsc-schedule-header-item {
  display: flex !important;
  justify-content: center !important;
  padding: 0 !important;
}

.header-template-container {
  padding: 0.4375em 0 !important;
}

.mbsc-range-day-start ~ div,
.mbsc-range-day-start {
  background-color: var(--accent-color-100);
}

.mbsc-range-day-start {
  border-radius: 20px 0px 0px 20px;
}

.mbsc-calendar-day-marked {
  padding-bottom: 0px !important;
  padding-top: 2px;
}

.mbsc-range-day-start + div + div + div + div + div + div {
  border-radius: 0px 20px 20px 0px;
}

.mbsc-calendar-title {
  font-size: medium !important;
}

@media only screen and (max-width: 800px) {
  .mbsc-schedule-time {
    width: 1rem;
  }
  .mbsc-schedule-time-col {
    width: 2.5rem !important;
  }
  .mbsc-calendar-row {
    height: 3rem;
  }
}

.mbsc-schedule-time-wrapper,
.mbsc-schedule-item {
  height: 4rem !important;
}
