.chapterItem:focus .subjectCard,
.programItem:focus .subjectCard,
.chapterItem .subjectCard:focus-visible,
.programItem .subjectCard:focus-visible {
    @apply ring-2;
    @apply ring-offset-2;
    @apply ring-accent-500;
}

.chapterItem:focus-visible,
.programItem:focus-visible {
    @apply outline-none;
}
